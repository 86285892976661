@import "../../../assets/fonts/fonts.css";

.change-room {
  h5,
  .form-control {
    color: #21295c;
    font-family: "Slug and Lion";
  }

  .form-control {
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  .btn-primary {
    background: #1c7293;
    font-size: 13px;
    font-family: Heebo;
    text-transform: uppercase;
    font-weight: 400;
    border: none;

    &:not(:disabled):not(.disabled):active {
      background-color: #4e8da8;
      border-color: #4e8da8;
    }
  }

  .btn-secondary {
    background: #9eb3c2;
    color: #ffffff;
    font-size: 13px;
    text-transform: uppercase;
    font-family: "Slug and Lion";
    border: none;

    &:hover,
    &:active {
      color: #fff !important;
      background-color: #c8d3dc !important;
      border-color: #c8d3dc !important;
      box-shadow: none !important;
    }
  }
}
