.subtitle {
	font-size: 0.7em;

	color: rgb(62, 58, 58);
	opacity: 0.6;
}

.subtitle-2 {
	font-size: 0.9em;
	color: rgb(85, 93, 101);
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
