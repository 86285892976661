.admin-view {
  .bg-dark {
    background-color: var(--bg-color) !important;
    .btn-outline-light {
      text-transform: uppercase;
      padding: 0.375rem 0.75rem;
      font-size: 0.75rem;
      font-weight: bold;
    }
    .btn-outline-light:hover {
      color: #142b50;
    }
    .btn-secondary {
      padding: 0.375rem 0.75rem;
      font-size: 0.75rem;
      line-height: 1.5;
    }
    .dropdown-menu {
      position: absolute;
      .dropdown-item {
        color: #21295c;
        a {
          color: #21295c;
          text-decoration: none;
        }
      }
    }
  }
}

.sidebar {
  min-width: 300px;
  max-width: 3000px;
  background: #1b3b6f;
  color: #adb5bd;
  right: -300px;
  transition: all 0.5s;
  position: absolute;
  min-height: 100vh;
  z-index: 9999999;
  transform: translateX(300px);
  &.is-open{
    transform: translateX(0px)
  }
  .nav-item{
   &:hover{
      background-color: #fafeff;
      color: #1b3b6f;
    } 
  }
  a{
    color: #fff;
    padding: 0.7rem 1rem;
    font-weight: 500;
    &:hover{
      color: #1b3b6f;
    }
    h5{
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.sidebar.is-open {
  position: absolute;
  right: 0;
  transition: .5s;
}


.sidebar-header {
  background: #313b4c;
  color: #adb5bd;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show, 
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
}

.nav-item:hover {
  background: lightblue;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

