.contact-page {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #21295c;
	text-align: left;
	h1,
	h2,
	h3,
	h4,
	h5,
	input {
		font-family: "Gabarito";
		color: #21295c;
	}

	.tiger {
		font-family: "Gabarito";
	}

	.container {
		padding: 15px 0 0;
		max-width: 850px;

		.row {
			box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
			padding: 4%;
			margin: 0 15px 30px;
		}
		.alert {
			border: none;
			color: #fff;
		}
		.alert-secondary {
			font-size: 1rem;
			font-family: "poppins";
			font-weight: bold;
			text-align: center;
			background-color: #8c9fac;
			border-color: #070b0e; //9eb3c2
			color: #fff;
			width: 100%;
		}
		.feedback-form {
			width: 100%;
			.form-group {
				input {
					color: #21295c;
					background-clip: padding-box;
					border: 1px solid rgba(0, 0, 0, 0.15);
					border-radius: 0.25rem;
				}
				textarea {
					width: 100%;
					height: 200px;
				}
			}
		}
		.btn-success {
			background: #4caf50;
			border: 0;
			padding: 15px;
			color: #ffffff;
			font-size: 14px;
			&:hover {
				background-color: #218838;
				border-color: #1e7e34;
			}
		}

		.btn-secondary {
			color: #21295c;
			background-color: #fff;
			border: none;
			font-family: "tiger";

			&:hover,
			&:active {
				background-color: #e2e9ed;
			}
		}
	}
	#small-screen {
		display: none;
		float: left;
	}

	#big-screen {
		display: block;
		margin-left: 10px;
	}

	@media only screen and (max-width: 992px) {
		#small-screen {
			display: inline;
		}

		#big-screen {
			display: none;
		}
	}
}
