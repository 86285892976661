@import "../../assets/fonts/fonts.css";

.room {
	.enter-room {
		@media screen and (min-width: 1090px) {
			max-width: 1090px;
			margin: 0 auto;
		}
	}
	h2 {
		font-family: "Slug and Lion", Arial, Helvetica, sans-serif;
	}
	.content-wrapper {
		margin-top: calc(53px + 10%);
		position: relative;
		//---maybe wrong
		height: calc(90vh - 97px);
	}
	.heebo {
		font-family: "Anton", sans-serif;
		font-weight: bold;
	}
	.slug-and-lion {
		font-family: "Slug and Lion", Arial, Helvetica, sans-serif;
	}

	.room-title {
		font-family: "Slug and Lion", Arial, Helvetica, sans-serif;
		font-size: 42px;
		text-transform: uppercase;
		color: #21295c;
	}

	.error {
		font-size: 12px;
		color: #db433c;
		margin-left: 5px;
		margin-top: 5px;
	}

	.main-content {
		background: rgba(158, 179, 194, 0.1);
		border-radius: 10px;
		margin: 0 10px;

		.name-enter {
			opacity: 1;
		}
		.name-enter-active {
			transition: background-color 400ms;
			background-color: rgba(28, 114, 147, 0.3);
		}
		.name-enter-done {
			opacity: 1;
			transition: all 400ms;
		}

		.content-title {
			text-align: center;
			font-size: 18px;
			text-transform: uppercase;
			color: #21295c;
			padding-top: 10px;
		}

		form {
			margin: 0 14px 20px;

			input {
				font-family: "Jost";
				text-transform: uppercase;
				height: 83px;
				font-size: 44px;
				color: #21295c;
			}
		}

		.btn-primary {
			background: #1c7293;
			font-size: 22px;
			text-transform: uppercase;
			font-weight: 400;
			border: none;
		}
	}

	.room-doesnt-exist {
		background: rgba(158, 179, 194, 0.1);
		border-radius: 10px;
		text-align: center;
		margin-top: calc(53px + 10%);
		position: relative;

		h2 {
			color: #1b3b6f;
		}

		.btn-primary {
			background: #1c7293;
			font-size: 13px;
			font-family: Heebo;
			text-transform: uppercase;
			font-weight: 400;
			border: none;

			&:not(:disabled):not(.disabled):active {
				background-color: #4e8da8;
				border-color: #4e8da8;
			}
		}
	}

	.media-buttons {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin-left: 0;
		width: 100%;
		padding-top: 8px;
		background-color: #ffffff;

		.btn-secondary {
			//background: #9eb3c2;
			background: #26516d;
			color: #ffffff;
			font-size: 13px;
			text-transform: uppercase;
			font-family: "Arial";
			border: none;
		}

		.zoom-btn {
			background: #007bff;
			border-radius: 25px;
			color: white;
			height: 25px;
			width: 25px;

			&:hover {
				background: #1b3b6f;
			}

			.svg-inline--fa {
				font-size: 14px;
				margin-left: 5px;
			}
		}
		.twitch-btn {
			color: #772ce8;
			font-size: 23px;

			&:hover {
				color: #1b3b6f;
			}

			.svg-inline--fa {
				margin-bottom: 4px;
			}
		}
		.trivia-media {
			.svg-inline--fa {
				font-size: 21px;
				color: #1b3b6f;
			}
		}
	}

	.with-ad {
		bottom: 90px;
	}
}


/* FOR Loader.js */
/* Center the loader container on the screen */
.loader-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh; /* Full viewport height */
	width: 100vw; /* Full viewport width */
	position: fixed;
	top: 0;
	left: 0;
	background-color: transparent; /* Add a background color if needed */
  }
  
  /* Style for the "Loading..." text */
  .loader-text {
	font-family: "Montserrat", sans-serif; /* Change font as needed */
	margin-bottom: 20px;
	font-weight: 600;
	font-size: 1.2rem;
	color: var(--bg-light); 
  }
  
  /* Existing .lds-grid styles */
  .lds-grid {
	/* change color here */
	color: var(--bg-light);
  }
  .lds-grid,
  .lds-grid div {
	box-sizing: border-box;
  }
  .lds-grid {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-grid div {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: currentColor;
	animation: lds-grid 1.2s linear infinite;
  }
  .lds-grid div:nth-child(1) {
	top: 8px;
	left: 8px;
	animation-delay: 0s;
  }
  .lds-grid div:nth-child(2) {
	top: 8px;
	left: 32px;
	animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(3) {
	top: 8px;
	left: 56px;
	animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(4) {
	top: 32px;
	left: 8px;
	animation-delay: -0.4s;
  }
  .lds-grid div:nth-child(5) {
	top: 32px;
	left: 32px;
	animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(6) {
	top: 32px;
	left: 56px;
	animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(7) {
	top: 56px;
	left: 8px;
	animation-delay: -0.8s;
  }
  .lds-grid div:nth-child(8) {
	top: 56px;
	left: 32px;
	animation-delay: -1.2s;
  }
  .lds-grid div:nth-child(9) {
	top: 56px;
	left: 56px;
	animation-delay: -1.6s;
  }
  @keyframes lds-grid {
	0%,
	100% {
	  opacity: 1;
	}
	50% {
	  opacity: 0.5;
	}
  }