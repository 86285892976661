@import "../../../assets/fonts/fonts.css";

.reset-password {
  height: 100vh;
  display: flex;
  align-items: center;
  h1 {
    color: #21295c;
    font-family: "tiger";
  }
  .container {
    max-width: 850px;

    .rat-img {
      width: 100%;
      max-width: 350px;
      height: auto;
    }

    form {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
      padding: 4%;
      margin: 0 15px 30px;

      .btn-success {
        display: flex;
        margin: 0 auto;
      }
    }

    .btn-secondary {
      display: flex;
      margin: 25px auto;
      color: #21295c;
      background-color: #fff;
      border: none;
      font-family: "tiger";

      &:hover,
      &:active {
        background-color: #e2e9ed;
      }
    }
  }
  .alert-warning {
    border-color: transparent;
  }
  .reset-form {
    min-width: 500px;
  }
  .error {
    color: red;
  }
}
