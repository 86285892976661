.player-header {
  background-color: var(--bg-color);
  position: fixed;
  width: 100%;
  height: 53px;
  max-height: 10vh;
  top: 0;
  z-index: 9;
  overflow: hidden;
  @media screen and (orientation: landscape) {
    position: relative;
    max-height: none;
  }

  .header-content {
    @media screen and (min-width: 1090px) {
      max-width: 1090px;
      margin: 0 auto;
    }
  }

  .room-code {
    background-color: #ffffff;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    color: #21295c;
    height: fit-content;

    span:first-child {
      font-size: 17px;
      font-weight: 500;
    }
    span:last-child {
      font-size: 18px;
      font-weight: 400;
    }
  }
  .btn-primary {
    font-family: Roboto;
    background-color: #1b3b6f;
    border-color: #1b3b6f;
    font-weight: 500;
  }
}
