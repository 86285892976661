.terms {
	margin: 40px;
	padding: 60px;
	border: 1px solid #ccc;
	font-size: 14px;
	color: #666;
	text-align: left;
	a {
		color: #666;
		text-decoration: underline;
	}
}
