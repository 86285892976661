.adslot {
	display: block;
	width: 100%;
	height: 300px;
	z-index: 999;
}

.adContainer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	min-height: 100px;
	background: "red";
}
/* Styles for the banner container */
.banner-container {
	position: relative;
	width: 100vw;
	max-width: 100%;
	overflow: hidden;
	min-height: 100px;
}

.pointer {
	cursor: pointer;
}

.pointer:hover {
	opacity: 0.7;
}

/* Styles for the banner image */
.banner-image {
	width: 100vw;
	min-height: 100px;
	height: auto;
	display: block;
}

/* Styles for the text overlay */
.overlay-text {
	position: absolute;
	width: 100vw;
	height: 100%;
	bottom: 0;
	left: 0;
	background-color: rgba(
		0,
		0,
		0,
		0.3
	); /* Adjust the background color and opacity as needed */
	color: #fff; /* Text color */
	padding-left: 10px;
	border-radius: 5px;
}

/* Styles for the header */
.overlay-title {
	font-size: 0.9em;
	font-weight: bold;
	margin-bottom: 0px;

	animation: slide-in 0.5s forwards; /* Apply the animation */
}

.overlay-text-small {
	font-size: 0.6em;
	color: gray;
}

.close-btn {
	font-size: 0.7em;
	color: gray;
}
/* Styles for the subtitle */
.overlay-subtitle {
	font-size: 0.7em;
	margin-bottom: 2px;
	color: white;
	animation: slide-in 0.9s forwards; /* Apply the animation */
}

/* Styles for the call to action button */
.cta-button {
	margin: 20px;
	transition: background-color 0.3s;
}

.cta-button:hover {
	background-color: #0056b3; /* Hover background color */
}

@keyframes slide-in {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}
