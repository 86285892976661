.twitch-score-table {
  margin-bottom: 1rem;
  color: #21295c;
  white-space: normal;
  line-height: normal;
  font-size: medium;
  font-style: normal;
  font-variant: normal;

  table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 600px;
    margin: 0 auto;

    tr td:first-child {
      border-top-left-radius: 10px;
    }
    tr td:last-child {
      border-top-right-radius: 10px;
    }
    tr td:first-child {
      border-bottom-left-radius: 10px;
    }
    tr td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  td {
    padding: 0.75rem;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 1.6em;
    font-family: "Tiger";
  }
}
h1 {
  color: #21295c;
  text-align: center;
  margin-top: 50px;
}
