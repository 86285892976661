@import "~bootstrap/scss/bootstrap";
@import "../../assets/fonts/fonts.css";

body {
	// font-family: "OpenSans";
	font-family: "Poppins", Helvetica, sans-serif;
}

.body-text {
	font-family: "Jost", Helvetica, sans-serif;
}

.no-print {
	@media print {
		display: none;
	}
}

.body-header {
	font-family: "Jost", Helvetica, sans-serif;
	font-size: 1.3rem;
	font-weight: 700;
}
/* TRANSITIONS */

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.fadeInUp {
	animation-name: fadeInUp;
	animation-duration: 0.5s; /* Adjust duration as needed */
	animation-fill-mode: both; /* Retain the final keyframe state */

	.slow {
		animation-duration: 1s;
	}
}

.fadeInUp.enter {
	opacity: 1;
	transform: translateY(0px);
}

.fadeInLeft {
	animation-name: fadeInLeft;
	animation-duration: 0.5s; /* Adjust duration as needed */
	animation-fill-mode: both; /* Retain the final keyframe state */
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translateX(-20px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeft.enter {
	opacity: 1;
	transform: translateX(0px);
}

$info: #6fb8bd;

/* END TRANSITIONS */
h1,
h2,
h3,
h4,
h5,
input {
	// font-family: "OpenSans";
	font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.title {
	text-transform: uppercase;
	font-weight: bold;
	font-family: "Poppins";
}
.label {
	color: rgb(44, 87, 134);
	text-transform: uppercase;
	font-family: "Gabarito", Verdana, Geneva, Tahoma, sans-serif;
	opacity: 0.8;
	font-size: 1rem;
}

.mode-switch {
	position: absolute;
	left: 144px;
	opacity: 0.9;
	top: 12px;

	button {
		font-size: 0.75rem;
		padding: 0.375rem 0.75rem;
		line-height: 1.8;
		border-radius: 50%;
	}
	span {
		font-size: 0.75rem;
		font-weight: 600;
		margin-left: 10px;
		color: #fff;
		text-transform: uppercase;
	}
}

.hideSmall {
	@media screen and (max-width: 500px) {
		display: none;
	}
}

.admin-view {
	& {
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.5;
		color: #21295c;
		text-align: left;
		// background-color: #edf3f7;
	}

	.header-title {
		font-family: "Montserrat", Helvetica, sans-serif;
		font-weight: 900;
		text-transform: uppercase;
		font-size: 1.2rem;
		@media screen and (max-width: 600px) {
			font-size: 1.5rem;
		}
	}

	.tiger {
		font-family: "poppins", Helvetica, sans-serif;
		text-transform: uppercase;
		font-weight: bold;
	}
	.text-muted {
		color: #9eb3c2 !important;
	}
	.text-warning {
		color: #f4a259 !important;
		a {
			color: #1c7293;
		}
	}

	.text-black {
		font-weight: bold; /* This will use OpenSans-Bold.ttf */
	}

	.text-bold {
		font-weight: 600; /* This will use OpenSans-SemiBold.ttf */
	}

	.text-body {
		font-weight: normal; /* This will use OpenSans-Regular.ttf */
	}

	.roboto-small {
		font-family: "Roboto", Arial, sans-serif;
		font-size: 0.75rem;
	}

	.corn {
		font-family: "corn";
	}

	.corn-small {
		font-family: "poppins", Verdana, Arial, sans-serif;
		text-transform: uppercase;
		font-weight: bold;
	}

	.page-preloader {
		min-height: calc(80vh);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.game-preloader {
		height: calc(100vh - 62px);
		width: 100%;
		z-index: 1000;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 0;
	}

	.error {
		color: red;
	}

	.yellowBg {
		background-color: orange;
		border-color: orange;
		color: #ffffff !important;
		h4 {
			margin: 0;
		}
	}

	.greenBg {
		background-color: #008c3e !important;
		border-color: #008c3e !important;
		color: #ffffff !important;
		h4 {
			margin: 0;
		}
	}

	.redBg {
		background-color: #d72f29 !important;
		border-color: #d72f29 !important;
		color: #ffffff !important;
		h4 {
			margin: 0;
		}
	}

	.btn {
		border-style: solid;
		text-transform: uppercase;
		font-weight: bold;
		box-shadow: none;
		&:hover {
			text-decoration: none;
		}
		&.edit-btn,
		&.edit-btn-bottom {
			color: #21295c;
		}
		.svg-inline--fa {
			font-size: 20px;
		}
		&.btn-success.btn-lg,
		&.btn-danger.btn-lg,
		&.btn-primary.btn-lg {
			font-size: 1.5rem;
			@media screen and (max-width: 400px) {
				font-size: 1.2rem;
			}
		}
		&.btn-success.btn-lg {
			margin-right: 10px;
		}
	}

	.images-buttons {
		button {
			padding: 0.375rem 0.75rem;
			font-size: 0.75rem;
			line-height: 1.5;
		}
	}

	.view-images {
		img {
			width: 220px;
			height: auto;
			filter: drop-shadow(0 0 0.75rem);
		}
		a {
			color: #158cba;
			text-decoration: none;
			background-color: transparent;
			cursor: pointer;
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.5;
			&:hover {
				color: #0d5875;
				text-decoration: underline;
			}
		}
	}

	.btn-lg {
		font-size: 1.09375rem;
		padding: 0.5rem 1rem;
	}
	.btn-sm {
		padding: 0.25rem 0.5rem;
		font-size: 0.625rem;
		line-height: 1.5;
		border-radius: 0.2rem;
	}

	.btn-outline-success {
		color: #1f8c22;
		border-color: #1f8c22;
		&:hover {
			color: #fff;
			background-color: #28b62c;
			border-color: #28b62c;
		}
	}

	.btn-outline-danger {
		color: #d72f29;
		border-color: #d72f29;
		&.btn-outline-danger:hover {
			color: #fff;
			background-color: #ff4136;
			border-color: #ff4136;
		}
	}

	.btn-outline-primary {
		color: #1c7293;
		border-color: #1c7293;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			background-color: #1c7293;
			border-color: #1c7293;
			color: #fff;
		}
	}
	.btn-warning {
		box-shadow: none;
		color: #222;
		background-color: $warning;
		border-color: #f4a259;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			color: #222;
			background-color: #f4a259;
			border-color: #f4a259;
			box-shadow: none;
		}
	}

	.btn-outline-dark {
		color: #1b3b6f;
		border-color: #1b3b6f;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			background-color: #1b3b6f;
			color: #ffffff;
		}
	}

	.btn-outline-info {
		color: #9eb3c2;
		border-color: #9eb3c2;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			color: #fff;
			background-color: #9eb3c2;
		}
	}

	.btn-secondary {
		color: #21295c;
		background-color: #fff;
		border: none;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			background-color: #e2e9ed;
		}
	}

	.btn-success {
		background-color: #1f8c22;
		border-color: #1f8c22;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			background-color: #52a04e;
			border-color: #52a04e;
		}
	}

	.btn-danger {
		border-color: #d72f29;
		background-color: $danger;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			background-color: #db433c;
			border-color: #db433c;
		}
	}

	.btn-info {
		color: #fff;
		background-color: #9eb3c2;
		border-color: #9eb3c2;
		&:hover,
		&:active {
			color: #fff;
			background-color: #c8d3dc !important;
			border-color: #c8d3dc !important;
			box-shadow: none !important;
		}
	}

	.btn-dark {
		border: none;
		background-color: rgba(13, 32, 46, 0.4);
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			transform: scale(1);
			-webkit-transition: all 0.3s ease;
			-moz-transition: all 0.3s ease;
			-o-transition: all 0.3s ease;
			transition: all 0.3s ease;
			background-color: rgba(13, 32, 46, 0.6);
		}
	}
	.btn-outline-warning {
		color: $warning;
		border-color: $warning;
		&:hover,
		&:not(:disabled):not(.disabled):active {
			color: #fff;
			background-color: $warning;
		}
	}

	.images-btn {
		font-size: 13px;
		.svg-inline--fa {
			font-size: 14px;
		}
	}

	.alert {
		color: #222;
		border-style: solid;
	}

	.alert-light {
		background-color: #f6f6f6;
		border-color: #e9e9e9;
	}

	.alert-primary {
		background-color: #7bb5cbe0;
		color: rgb(21, 54, 114);
	}

	.alert-info {
		font-size: 1.4rem;
		text-align: center;
		background-color: #4e6576;
		border-color: #9eb3c2;
		color: #fff;
	}

	.playBtn {
		margin-bottom: 2px;
		height: 20px;
		width: 20px;
	}

	.alert-info-custom {
		background-color: #9eb3c2;
		border-color: #9eb3c2;
		.btn {
			padding: unset;
			.svg-inline--fa {
				font-size: 0.75rem;
				color: #1b3b6f;
			}
		}
		.btn-lg {
			font-size: 1.09375rem;
			padding: 0.5rem 1rem;
			.svg-inline--fa {
				font-size: inherit;
			}
		}
		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.alert-success {
		background-color: #f6f8f9;
		border: none;
		color: #21295c;

		box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.07);
		.progress {
			width: 300px;
		}
		.bg-warning {
			background-color: #1c7293 !important;
		}
	}

	.alert-danger {
		background-color: #dc3545;
		border-color: #dc3545;
		color: #fff;
		a {
			color: #fff;
			text-decoration: underline;
			font-weight: 500;
			&:hover {
				color: #ffe0e3;
				text-decoration: none;
			}
		}
	}

	.alert-green {
		background-color: #23b950;
		border-color: #066804;
		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.alert-secondary {
		background-color: #e5e5e5;
		border-color: #e5e5e5;
		color: #1b3b6f !important;

		.btn {
			padding: unset;
			.svg-inline--fa {
				font-size: 0.75rem;
			}
		}
		.btn-lg {
			font-size: 1.09375rem;
			padding: 0.5rem 1rem;
			.svg-inline--fa {
				font-size: inherit;
			}
		}
		a {
			text-decoration: underline;
		}
	}

	.text-sm {
		font-size: 0.5em;
	}

	.alert-warning {
		background-color: #f4a259;
		border-color: #f4a259;

		.close {
			color: rgba(255, 255, 255, 0.5);
			text-shadow: none;
		}
	}
	.tip {
		font-size: 0.65rem;
	}

	.text-link {
		color: #2aa198;
	}

	.modal-content {
		.alert-info {
			font-size: 0.875rem;
		}
		.modal-footer {
			padding: 0;
		}
	}

	.card {
		width: 100%;
		text-align: center;
		.text-info {
			color: #75caeb !important;
		}
	}

	.bg-success {
		background-color: #28b671 !important;
	}

	.marking-section {
		.answers {
			border-radius: 25px;
			border: 2px solid #dcdcdc;
			padding: 20px;

			select {
				max-width: 100px;
				margin: 0 auto;
			}

			.bed {
				font-size: 0.875rem;
				transform: scale(2.3);
			}
		}
		.btn-secondary {
			font-size: 10px;
			.svg-inline--fa {
				font-size: 10px;
			}
		}
		.arrow {
			position: fixed;
			font-size: 18px;
			top: 40%;
			opacity: 0.7;
			height: 100px;
			vertical-align: center;
			display: inline-flex;
			align-items: center;
			z-index: 999;
		}
		.right-arrow {
			right: 0;
		}
		.left-arrow {
			left: 0;
		}
		.question-image {
			img {
				width: auto;
				max-height: 200px;
				filter: drop-shadow(0 0 0.75rem);
			}
		}
	}

	.modal-body {
		.settings-link {
			color: #158cba;
			text-decoration: none;
			background-color: transparent;
			cursor: pointer;
			&:hover {
				color: #0d5875;
				text-decoration: underline;
			}
		}
	}

	.tips-modal {
		.btn {
			font-size: 0.75rem;
		}
	}

	.game-component {
		.btn-success,
		.btn-danger {
			.svg-inline--fa {
				height: 1.5rem;
			}
		}
	}

	.game-container {
		position: relative;
	}

	.game-button {
		border-radius: 40px;
		width: 80%;
		align-items: center;
		display: flex;
		justify-content: center;
	}

	.game-button-container {
		background-color: #f2f2f2; /* #dfe7ec;*/
		padding: 30px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		// box-shadow: 0px -10px 5px 0px rgba(0, 0, 0, 0.1);
		// -webkit-box-shadow: 0px -10px 5px 0px rgba(0, 0, 0, 0.1);
		// -moz-box-shadow: 0px -10px 5px 0px rgba(0, 0, 0, 0.1);
		box-shadow: rgba(0, 0, 0, 0.19) 0px -2px 20px,
			rgba(0, 0, 0, 0.23) 0px 6px 6px;
		-webkit-box-shadow: rgba(0, 0, 0, 0.19) 0px -2px 20px,
			rgba(0, 0, 0, 0.23) 0px 6px 6px;
		-moz-box-shadow: rgba(0, 0, 0, 0.19) 0px -2px 20px,
			rgba(0, 0, 0, 0.23) 0px 6px 6px;
		z-index: 1000;
	}

	.fame-table {
		tbody {
			tr {
				.player {
					padding-right: 8rem;
					padding-left: 2rem;
					@media screen and (max-width: 550px) {
						padding-left: 10px;
						padding-right: 6rem;
					}
					h2 {
						font-size: 3.5rem;
						@media screen and (max-width: 550px) {
							font-size: 2rem;
						}
					}
				}
				.trophy {
					vertical-align: middle;
				}
				.gold {
					color: gold;
				}
				.silver {
					color: silver;
				}
			}
		}
		.btn-primary {
			font-size: 0.75rem;
		}
	}

	@media only screen and (max-width: 992px) {
		#small-screen {
			display: inline;
		}

		#big-screen {
			display: none;
		}

		.corn-small {
			font-size: 0.7em;
			line-height: 70% !important;
		}
	}

	.with-ad {
		margin-bottom: 90px;
	}
}

// you can write custom css here

.btn-light {
	color: #212529;
	background-color: #ebebeb;
	border-color: #ebebeb;
}

.btn-primary {
	color: #fff;
	background-color: #1c7293 !important;
	border-color: #1c7293 !important;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		background-color: #4e8da8;
		border-color: #4e8da8;
	}
}
